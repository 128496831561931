<template>
  <div class="example">
    {{ msg }}
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: '500'
    }
  }
}
</script>

<style>
  .example {
    color: red;
  }
</style>
